var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.calculateLabelContainerMaxWidth),expression:"calculateLabelContainerMaxWidth"}],staticClass:"d-flex align-center top-bar-title",style:(_vm.$vuetify.breakpoint.mdAndDown ? 'max-width: 100%' : '')},[(!_vm.$vuetify.breakpoint.mdAndDown)?_c('router-link',{staticClass:"mr-6 back-button",attrs:{"to":"/"}},[_c('v-icon',{staticClass:"heading--text",attrs:{"size":"24"}},[_vm._v("$arrowleft")])],1):_vm._e(),_c('div',{staticClass:"d-flex w-100"},[_c('div',{staticClass:"top-bar-title__container"},[(!_vm.fetching)?[(!_vm.instance.isActive)?_c('strong',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.$t("message.status.instance.notActive")))]):_vm._e(),_c('v-menu',{staticClass:"top-bar-title__menu",attrs:{"offset-y":"","max-width":"calc(100vw - 48px)","min-width":"200px","width":"354px","translate":"slide-y","close-on-content-click":false,"content-class":"instance-search-menu disable-scroll"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('top-bar-editable-title',_vm._g({attrs:{"instance":_vm.instance,"attrs":attrs},on:{"click":function($event){_vm.setFocus();
                _vm.menuOpened();}}},on))]}}],null,false,13692011)},[_c('v-text-field',{ref:"searchTextField",attrs:{"hide-details":"auto","prepend-inner-icon":"$search","placeholder":_vm.$t('form.placeholder.searchInstance')},on:{"input":_vm.debounceSearchResults}}),(!_vm.searchError && _vm.searchResults.length)?_c('div',{staticClass:"instance-search-menu__list"},_vm._l((_vm.searchResults),function(item,index){return _c('instance-search-item',{key:index,attrs:{"instance":item},nativeOn:{"click":function($event){return _vm.$emit('switch-instance', item.id)}}})}),1):(!_vm.searchResults.length && !_vm.searchError)?_c('div',_vm._l((3),function(item){return _c('instance-search-item-skeleton',{key:item})}),1):_c('p',{staticClass:"instance-search-menu__list text-center p-2 mt-4"},[_vm._v(" "+_vm._s(_vm.$t('message.notFound', {searchField: _vm.searchField}))+" ")])],1),_c('div',{staticClass:"top-bar-title__box d-flex",class:[
            _vm.$vuetify.breakpoint.smAndDown
              // ? 'flex-column align-start'
              ? 'flex-wrap align-center'
              : 'align-center',
            _vm.$vuetify.breakpoint.lg ? 'mb-6' : '',
          ]},[_c('a',{staticClass:"p-3 mr-4 mt-4 instance-domain-link",attrs:{"href":_vm.instance.url,"target":"_blank"}},[_vm._v(_vm._s(_vm.instance.url))]),[_c('set-your-domain-modal',_vm._g({staticClass:"mt-4",class:_vm.$vuetify.breakpoint.smAndDown ? '' : '',attrs:{"instance":_vm.instance},on:{"dns-propagation-in-progress":function($event){return _vm.$emit('dns-propagation-in-progress')}}},_vm.$listeners))],_c('div',{ref:"labelContainer",staticClass:"labelContainer mt-4",class:_vm.$vuetify.breakpoint.smAndDown ? '' : ''},[_c('instance-labels',{staticClass:"w-100",attrs:{"chips":true,"removable":false,"labels":_vm.instance.labels,"remainingAsButton":true,"maxWidth":_vm.maxLabelWidth,"maxLabelsDisplayed":2,"instance":_vm.instance,"filtersActive":true},on:{"toggleFilter":_vm.searchFilter}})],1)],2)]:_c('div',{staticClass:"d-flex skeleton-loader flex-column"},[_c('v-skeleton-loader',{staticClass:"header",attrs:{"type":"header"}}),_c('v-skeleton-loader',{staticClass:"subheader",attrs:{"type":"text, chip@2"}})],1)],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }