<template>
  <div class="editor">
    <h1
      v-if="!isEditorOpen"
      class="editor__title top-bar-title__button"
      v-bind="attrs"
      v-on="$listeners"
    >
      <span class="top-bar-title__button-text">
        {{ instance.title }}
      </span>
      <v-icon size="24" class="heading--text">$chevrondown</v-icon>
    </h1>

    <v-form ref="form" v-if="isEditorOpen" class="editor__input-box">
      <v-text-field
        @keydown.enter.prevent="updateSiteName"
        class="h1"
        v-model="editInput"
        :loading="loading"
        :disabled="loading"
      />
    </v-form>

    <div class="editor__buttons" v-if="instance.getUserPrivileges('wordpress.update_site_name')">
      <div class="editor__controls editor__controls--open " v-if="!isEditorOpen">
        <v-btn icon @click="isEditorOpen = true" :disabled="loading">
          <v-icon> $edit </v-icon>
        </v-btn>
      </div>
      <div
        class="editor__controls editor__controls--closed"
        v-if="isEditorOpen"
      >
        <v-btn color="primary" icon @click="updateSiteName" :disabled="loading">
          <v-icon> $check </v-icon>
        </v-btn>
        <v-btn icon :disabled="loading">
          <v-icon @click="isEditorOpen = false"> $close </v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      isEditorOpen: false,
      editInput: "",
    };
  },
  mounted: function () {
    this.editInput = this.instance.title;
  },
  computed: {
    loading: function () {
      return this.$store.state.InstanceModule.nameChangingActionInProgress;
    },
  },
  watch: {
    loading: function(newValue) {
      if(!newValue) {
        this.isEditorOpen = false
      }
    }
  },
  props: {
    instance: Object,
    attrs: {
      type: Object,
    },
  },
  methods: {
    updateSiteName() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch("updateSiteName", {
          instance: this.instance,
          newName: this.editInput,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.editor {
  display: flex;
  align-items: center;
  // max-height: 40px;
  @media (min-width: 976px){
    width: 565px;
    max-width: 700px;
    margin-bottom: 8px;
  }
  .v-input::v-deep {
    margin-top: 0px;
    padding-top: 0px;
    // font-size: 32px;
    // line-height: 40px;
    // font-weight: $font-weight-extrabold;
    input {
      padding-bottom: 4px !important;
    }
  }
  &__buttons {
    margin-left: 12px;
    transition: opacity 0.24s ease;
    display: flex;
    align-items: center;
  }
  &__controls--open {
    opacity: 0;
  }
  &:hover {
    .editor {
      &__controls--open {
        opacity: 1;
      }
    }
  }
  .v-btn:hover {
    color: map-get($primary, base);
  }
  .v-form .v-input.v-text-field {
    margin-bottom: 0px;
  }
}
.v-application--is-rtl{
    .editor{
        margin-left: auto;
        max-width: max-content;
    }
}
</style>