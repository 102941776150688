<template>
  <router-link
    :to="`/instances/${instance.id}/general`"
    class="d-flex search-item"
  >
    <instance-avatar
      :instance="instance"
      :size="40"
    ></instance-avatar>
    <div class="d-flex flex-column search-item__content">
      <h6>
        {{ instance.title }}
      </h6>
      <div class="d-flex">
        <a :href="instance.url" class="instance-domain-link p-3">
          {{ instance.url }}
        </a>
        <labels-list dots :labels="instance.labels" :maxLabelsDisplayed="2" />
      </div>
    </div>
  </router-link>
</template>

<script>
import InstanceAvatar from "../InstanceAvatar.vue";
import LabelsList from "../labels/LabelsList.vue";

export default {
  components: { InstanceAvatar, LabelsList },
  props: {
    instance: Object,
  },
};
</script>

<style lang="scss" scoped>
.search-item {
  padding: 16px;
  transition: background-color 0.3s ease;
  background-color: var(--v-tooltip-base);
  &:hover {
    background-color: var(--v-primary-lighten5);
  }
  a {
    color: map-get($gray, darken1);
  }
  &__content {
    margin-left: 16px;
  }
}
</style>