<template>
  <div
    class="subinstance-bar px-6"
    :class="`${
      instance.colorScheme ? `subinstance-bar--${instance.colorScheme}` : ''
    } ${fetching ? 'subinstance-bar--fetching' : ''}`"
  >
    <v-container class="px-0 d-flex align-end">
      <template v-if="!fetching || this.switching">
        <!-- LIVE -->
        <v-menu
          v-if="parentInstance !== null"
          transition="custom-tooltip"
          open-delay="150"
          bottom
          z-index="99"
          offset-overflow
          :disabled="!state(parentInstance)"
          open-on-hover
          :close-on-content-click="false"
          nudge-left="60px"
          min-width="272px"
          nudge-bottom="32px"
          origin="top center"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              class="subinstance-bar__item"
              :class="[
                parentInstance.hasActionInProgress()
                  ? 'subinstance-bar__in-progress'
                  : '',
                parentInstance.colorScheme
                  ? `subinstance-bar__item--${parentInstance.colorScheme}`
                  : '',
                state(parentInstance) === 'in_progress'
                  ? 'subinstance-bar__in-progress'
                  : '',
                state(parentInstance) === 'error'
                  ? 'subinstance-bar__error'
                  : '',
                activeTab == parentInstance.id ? 'v-btn--active' : '',
              ]"
              v-ripple="false"
              elevation="0"
              @click="
                state(parentInstance) === 'error' ||
                state(parentInstance) === 'in_progress'
                  ? undefined
                  : switchInstance(parentInstance)
              "
            >
              <loader
                :size="20"
                :color="parentInstance.colorScheme"
                v-if="parentInstance.hasActionInProgress()"
                class="loader"
              />
              <v-icon
                :size="16"
                color="error"
                v-if="state(parentInstance) === 'error'"
                class="mr-2"
              >
                $xcircle
              </v-icon>
              {{ parentInstance.subinstanceLabel }}
            </v-btn>
          </template>
        </v-menu>

        <!-- STAGINGS -->
        <v-menu
          transition="custom-tooltip"
          open-delay="150"
          bottom
          z-index="99"
          offset-overflow
          v-for="(item, index) in subInstances"
          :key="index"
          :disabled="!state(item)"
          open-on-hover
          :close-on-content-click="false"
          nudge-left="60px"
          min-width="272px"
          nudge-bottom="32px"
          origin="top center"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-on="on"
              v-bind="attrs"
              class="subinstance-bar__item"
              :class="[
                'ml-2',
                item.hasActionInProgress()
                  ? 'subinstance-bar__in-progress'
                  : '',
                item.colorScheme
                  ? `subinstance-bar__item--${item.colorScheme}`
                  : '',
                state(item) === 'in_progress'
                  ? 'subinstance-bar__in-progress'
                  : '',
                state(item) === 'error' ? 'subinstance-bar__error' : '',
                activeTab == item.id ? 'v-btn--active' : '',
              ]"
              v-ripple="false"
              elevation="0"
              @click="
                state(item) === 'error' || state(item) === 'in_progress'
                  ? undefined
                  : switchInstance(item)
              "
            >
              <loader
                :size="20"
                :color="item.colorScheme"
                v-if="item.hasActionInProgress()"
                class="loader"
              />
              <v-icon
                :size="16"
                color="error"
                v-if="state(item) === 'error'"
                class="mr-2"
              >
                $xcircle
              </v-icon>
              {{ item.subinstanceLabel }}
            </v-btn>
          </template>
          <div class="px-4 py-4 text-center">
            <template v-if="state(item) == 'in_progress'">
              <p class="p-4 mb-2">
                <i18next :translation="$t('notification.instance.topBar.subInstance.inProgress.plain')">
                  <template #highlighted>
                    <strong>{{ $t('notification.instance.topBar.subInstance.inProgress.highlighted') }}</strong>
                  </template>
                </i18next>
              </p>
              <v-btn
                elevation="0"
                max-height="24px"
                class="px-2"
                color="error"
                x-small
              >
                <span class="p-5">{{ $t('button.cancelClone') }}</span>
              </v-btn>
            </template>
            <template v-else-if="state(item) == 'error'">
              <p class="p-4 mb-2">
                <i18next :translation="$t('notification.instance.topBar.subInstance.error.plain')">
                  <template #highlighted>
                    <strong>{{ $t('notification.instance.topBar.subInstance.error.highlighted') }}</strong>
                  </template>
                </i18next>
              </p>
              <div class="d-flex justify-center">
                <v-btn
                  elevation="0"
                  max-height="24px"
                  class="px-2"
                  color="primary"
                  x-small
                  @click="$emit('action-button-retry-staging', item)"
                >
                  <span class="p-5">{{ $t('button.try') }}</span>
                </v-btn>
                <v-btn
                  elevation="0"
                  max-height="24px"
                  color="error"
                  x-small
                  class="ml-2 px-2"
                  @click="$emit('action-button-cancel-staging', item)"
                >
                  <span class="p-5">{{ $t('button.cancel') }}</span>
                </v-btn>
              </div>
            </template>
          </div>
        </v-menu>
        <v-tooltip
          transition="custom-tooltip"
          open-delay="150"
          bottom
          nudge-right="4px"
          z-index="99"
          v-if="subInstances.length < 1 && (instance.getUserPrivileges('general.create_staging'))"
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on" v-bind="attrs">
              <v-btn
                icon
                class="subinstance-bar__add"
                @click="$emit('action-button-staging', instance)"
              >
                <v-icon size="16" class="heading--text">$plus</v-icon>
              </v-btn>
            </div>
          </template>
          <span>{{ $t('tooltip.topBar.subInstance.newStaging') }}</span>
        </v-tooltip>
      </template>
      <template v-else>
        <div class="subinstance-bar__skeleton-container">
          <v-skeleton-loader type="avatar" />
          <v-skeleton-loader class="g" type="header" />
        </div>
      </template>
    </v-container>
  </div>
</template>

<script>
import Loader from "@/components/Loader";
export default {
  components: {
    Loader,
  },
  props: {
    instance: Object,
    fetching: Boolean,
  },
  data() {
    return {
      activeTab: this.$route.params.id,
    };
  },
  computed: {
    parentInstance() {
      return this.instance.staging
        ? this.instance.parentInstance
        : this.instance;
    },
    subInstances() {
      if (this.instance.staging) {
        return [this.instance];
      }
      return this.instance.subInstances;
    },
    switching() {
      return this.instance.id;
    },
  },
  methods: {
    switchInstance(item) {
      this.activeTab = item.id;
      this.$root.$emit("instance-changed", item);
    },
    state: function (subinstance) {
      if (subinstance === null) {
        return false;
      }

      return subinstance.hasInstallStatus()
        ? subinstance.hasInstallFailed()
          ? "error"
          : "in_progress"
        : false;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-tabs::v-deep {
  .v-tabs-bar {
    background: transparent;
  }
}

.subinstance-bar__item.v-btn::v-deep {
  .v-btn__content {
    padding-left: 18px;
    color: map-get($text, headings);
    font-size: $font-size-sm;
    line-height: $line-height-sm;
    position: relative;
    text-transform: capitalize;
    font-weight: $font-weight-bold;
    &::before {
      content: "";
      height: 8px;
      width: 8px;
      position: absolute;
      background: map-get($primary, base);
      left: 0;
      top: calc(50% - 4px);
      border-radius: 50%;
    }
  }
}

.subinstance-bar__item--primary.v-btn::v-deep {
  .v-btn__content {
    &::before {
      background: map-get($success, base);
    }
  }
}

.subinstance-bar__item--info.v-btn::v-deep {
  .v-btn__content {
    &::before {
      background: map-get($info, base);
    }
  }
}

.is-dark {
  .subinstance-bar {
    background: var(--v-bar-base) !important;
  }
}

.subinstance-bar {
  width: 100%;
  background-color: var(--v-primary-lighten4) !important;
  transition: background-color 0.3s ease;
  .loader {
    margin-left: -20px;
    margin-right: 4px;
  }
  &--info {
    background-color: map-get($info, lighten4);
  }
  &--primary {
    background-color: map-get($primary, lighten5);
  }
  &--fetching {
    background-color: map-get($gray, lighten2);
  }
  &__error {
    &.v-btn::v-deep {
      .v-btn__content {
        padding-left: 0;
        color: map-get($error, base);
      }
    }
  }
  &__in-progress,
  &__error {
    &.v-btn::v-deep {
      .v-btn__content {
        &::before {
          display: none;
        }
      }
    }
  }
  .container {
    padding-bottom: 0px;
    .subinstance-bar__add {
      max-height: 31px;
      max-width: 31px;
      margin-left: 8px;
    }
    .subinstance-bar__item {
      border-radius: 6px 6px 0px 0px;
      background: var(--v-secondary-darken1);
      max-height: 31px;
      transition: none !important;
      &::before {
        display: none;
      }
      &.v-btn--active {
        background: var(--v-body-base);
        transition: none !important;
      }
      + .subinstance-bar__item {
        margin-left: 8px;
      }
    }
  }
  &__skeleton-container {
    height: 31px;
    width: 98px;
    display: flex;
    align-items: center;
    padding: 13px 16px;
    background-color: var(--v-body-base);
    border-radius: 6px 6px 0 0;
    .v-skeleton-loader::v-deep {
      .v-skeleton-loader {
        &__avatar {
          width: 8px;
          height: 8px;
        }
        &__header {
          height: 8px;
          width: 48px;
          border-radius: 3px;
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
